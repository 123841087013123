import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Page404 from '../Page/Page404/Page404';
import RouteWrapper from './RouteWrapper';

const PagePurchasedProducts = React.lazy(
  () => import('../Page/PagePurchasedProducts/PagePurchasedProducts')
);
const PageAllUsers = React.lazy(
  () => import('../Page/PageAdministration/PageAllUsers')
);
const PageLocations = React.lazy(
  () => import('../Page/PageLocations/PageLocations')
);
const PageEvents = React.lazy(() => import('../Page/PageEvents/PageEvents'));
const PageJobs = React.lazy(() => import('../Page/PageJobs/PageJobs'));
const PageNotification = React.lazy(
  () => import('../Page/PageNotification/PageNotification')
);

const PageWhatsup = React.lazy(() => import('../Page/PageWhatsup/PageWhatsup'));
const PageLanding = React.lazy(() => import('../Page/PageLanding/PageLanding'));
const PageProfile = React.lazy(() => import('../Page/PageProfile/PageProfile'));
const PageShowProfile = React.lazy(
  () => import('../Page/PageProfile/PageShowProfile')
);
const PageExplore = React.lazy(() => import('../Page/PageExplore/PageExplore'));
const PageAffiliate = React.lazy(
  () => import('../Page/PageAffiliate/PageAffiliate')
);
const PageAccounts = React.lazy(
  () => import('../Page/PageAccounts/PageAccounts')
);
const PageEvent = React.lazy(() => import('../Page/PageEvent/PageEvent'));
const PageLocation = React.lazy(
  () => import('../Page/PageLocation/PageLocation')
);
const PageDashboard = React.lazy(
  () => import('../Page/PageDashboard/PageDashboard')
);
const PageMessages = React.lazy(
  () => import('../Page/PageMessages/PageMessages')
);
// const PagePaidMessages = React.lazy(
//   () => import('../Page/PagePaidMessages/PagePaidMessages')
// );
const PageAdminAccounting = React.lazy(
  () => import('../Page/PageAdministration/PageAdminAccounting')
);
const PageAdministration = React.lazy(
  () => import('../Page/PageAdministration/PageAdministration')
);
const PageDeletedContent = React.lazy(
  () => import('../Page/PageDeletedContent/PageDeletedContent')
);
const PageSupervisor = React.lazy(
  () => import('../Page/PageSupervisor/PageSupervisor')
);
const PageSettings = React.lazy(
  () => import('../Page/PageSettings/PageSettings')
);
const PageDivisions = React.lazy(
  () => import('../Page/PageDivisions/PageDivisions')
);
const PageProduct = React.lazy(() => import('../Page/PageProduct/PageProduct'));
const PageProducts = React.lazy(
  () => import('../Page/PageProducts/PageProducts')
);
const PageProjects = React.lazy(
  () => import('../Page/PageProjects/PageProjects')
);
const PageProject = React.lazy(() => import('../Page/PageProject/PageProject'));
const PageWorklists = React.lazy(
  () => import('../Page/PageWorklists/PageWorklists')
);

const Messages = React.lazy(() => import('../Page/PageMessages/Messages'));
const PageTranslations = React.lazy(
  () => import('../Page/PageTranslations/PageTranslations')
);
const PageLocalization = React.lazy(
  () => import('../Page/PageLocalization/PageLocalization')
);
const PageLandingPageSetup = React.lazy(
  () => import('../Page/PageLandingPageSetup/PageLandingPageSetup')
);
const PageTax = React.lazy(() => import('../Page/PageTax/PageTax'));
// const PagePermissions = React.lazy(
// () => import('../Page/PagePermissions/PagePermissions')
// );
const PageSupport = React.lazy(() => import('../Page/PageSupport/PageSupport'));
const PageWishlist = React.lazy(
  () => import('../Page/PageWishlist/PageWishlist')
);
const PageJob = React.lazy(() => import('../Page/PageJob/PageJob'));
const PageWorklist = React.lazy(
  () => import('../Page/PageWorklist/PageWorklist')
);
const PageOnline = React.lazy(() => import('../Page/PageOnline/PageOnline'));
const PageAffiliator = React.lazy(
  () => import('../Page/PageLanding/PageAffliateLanding')
);
const PageConstant = React.lazy(
  () => import('../Page/PageConstant/PageConstant')
);
const PageDocument = React.lazy(
  () => import('../Page/PageDocument/PageDocument')
);
const PageDocumentView = React.lazy(
  () => import('../Page/PageDocument/PageDocumentView')
);
const PageChangelog = React.lazy(
  () => import('../Page/PageChangelog/PageChangelog')
);

const PageMoodboards = React.lazy(
  () => import('../Page/PageMoodboards/PageMoodboards')
);
const PageMoodboard = React.lazy(
  () => import('../Page/PageMoodboards/PageMoodboard')
);
const PageMessagesNew = React.lazy(
  () => import('../atomic-design/pages/PageMessages')
);

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <RouteWrapper isPublicOnly>
        <PageLanding />
      </RouteWrapper>
    ),
  },
  {
    path: '/affiliator/:id',
    element: (
      <RouteWrapper isPublicOnly>
        <PageAffiliator />
      </RouteWrapper>
    ),
  },
  {
    path: '/online',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageOnline />
      </RouteWrapper>
    ),
  },
  {
    path: '/notification',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageNotification />
      </RouteWrapper>
    ),
  },
  {
    path: '/credits',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageAccounts />
      </RouteWrapper>
    ),
  },
  {
    path: '/whatsup',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageWhatsup />
      </RouteWrapper>
    ),
  },
  {
    path: '/dashboard',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageDashboard />
      </RouteWrapper>
    ),
  },
  {
    path: '/messages',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageMessages />
      </RouteWrapper>
    ),
  },
  // new
  {
    path: '/messages-new',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageMessagesNew />
      </RouteWrapper>
    ),
  },
  // new

  {
    path: '/accounting',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageAdminAccounting />
      </RouteWrapper>
    ),
  },
  // {
  //   path: '/paid-messages',
  //   element: (
  //     <RouteWrapper isPrivateOnly>
  //       <PagePaidMessages />
  //     </RouteWrapper>
  //   ),
  // },
  {
    path: '/messages/:messageID',
    element: (
      <RouteWrapper isPrivateOnly>
        <Messages />
      </RouteWrapper>
    ),
  },
  {
    path: '/products',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageProducts />
      </RouteWrapper>
    ),
  },
  {
    path: '/purchased-products',
    element: (
      <RouteWrapper isPrivateOnly>
        <PagePurchasedProducts />
      </RouteWrapper>
    ),
  },
  {
    path: '/projects',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageProjects />
      </RouteWrapper>
    ),
  },
  {
    path: '/locations',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageLocations />
      </RouteWrapper>
    ),
  },
  {
    path: '/project/:projectID',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageProject />
      </RouteWrapper>
    ),
  },

  {
    path: '/moodboards',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageMoodboards />
      </RouteWrapper>
    ),
  },
  {
    path: '/moodboard/:id',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageMoodboard />
      </RouteWrapper>
    ),
  },
  {
    path: '/explore',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageExplore />
      </RouteWrapper>
    ),
  },
  {
    path: '/support',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageSupport />
      </RouteWrapper>
    ),
  },
  {
    path: '/worklists',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageWorklists />
      </RouteWrapper>
    ),
  },
  {
    path: '/events',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageEvents />
      </RouteWrapper>
    ),
  },
  {
    path: '/jobs',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageJobs />
      </RouteWrapper>
    ),
  },
  {
    path: '/worklist/:worklistID',
    element: (
      <RouteWrapper isPrivateOnly>
        <PageWorklist />
      </RouteWrapper>
    ),
  },
  {
    path: `/profile/:profileID`,
    element: (
      <RouteWrapper isBoth>
        <PageShowProfile />
      </RouteWrapper>
    ),
  },
  {
    path: `/profile`,

    element: (
      <RouteWrapper isPrivateOnly>
        <PageProfile />
      </RouteWrapper>
    ),
  },
  {
    path: `/constants`,

    element: (
      <RouteWrapper isPrivateOnly>
        <PageConstant />
      </RouteWrapper>
    ),
  },
  {
    path: `/documents`,

    element: (
      <RouteWrapper isPrivateOnly>
        <PageDocument />
      </RouteWrapper>
    ),
  },
  {
    path: `/settings`,
    element: (
      <RouteWrapper isPrivateOnly>
        <PageSettings />
      </RouteWrapper>
    ),
  },
  {
    path: `/divisions`,
    element: (
      <RouteWrapper isPrivateOnly>
        <PageDivisions />
      </RouteWrapper>
    ),
  },
  {
    path: `/supervisor`,
    element: (
      <RouteWrapper isPrivateOnly>
        <PageSupervisor />
      </RouteWrapper>
    ),
  },
  {
    path: `/administration`,
    element: (
      <RouteWrapper isPrivateOnly>
        <PageAdministration />
      </RouteWrapper>
    ),
  },
  {
    path: `/all-users`,
    element: (
      <RouteWrapper isPrivateOnly>
        <PageAllUsers />
      </RouteWrapper>
    ),
  },
  {
    path: `/deleted-content`,
    element: (
      <RouteWrapper isPrivateOnly>
        <PageDeletedContent />
      </RouteWrapper>
    ),
  },
  {
    path: `/translations`,
    element: (
      <RouteWrapper isPrivateOnly>
        <PageTranslations />
      </RouteWrapper>
    ),
  },
  {
    path: `/localization`,
    element: (
      <RouteWrapper isPrivateOnly>
        <PageLocalization />
      </RouteWrapper>
    ),
  },
  {
    path: `/landing-page-setup`,
    element: (
      <RouteWrapper isPrivateOnly>
        <PageLandingPageSetup />
      </RouteWrapper>
    ),
  },
  {
    path: `/tax`,
    element: (
      <RouteWrapper isPrivateOnly>
        <PageTax />
      </RouteWrapper>
    ),
  },
  {
    path: `/wishlist`,
    element: (
      <RouteWrapper isPrivateOnly>
        <PageWishlist />
      </RouteWrapper>
    ),
  },
  // {
  //   path: `/permissions`,
  //   element: (
  //     <RouteWrapper isPrivateOnly>
  //       <PagePermissions />
  //     </RouteWrapper>
  //   ),
  // },
  // {
  //   path: `/paypallink`,
  //   element: (
  //     <RouteWrapper isPrivateOnly>
  //       <PagePaypalLink />
  //     </RouteWrapper>
  //   ),
  // },
  {
    path: `/affiliate`,
    element: (
      <RouteWrapper isPrivateOnly>
        <PageAffiliate />
      </RouteWrapper>
    ),
  },
  {
    path: `/event/:eventID`,
    element: (
      <RouteWrapper isBoth>
        <PageEvent />
      </RouteWrapper>
    ),
  },
  {
    path: `/product/:id`,
    element: (
      <RouteWrapper isBoth>
        <PageProduct />
      </RouteWrapper>
    ),
  },
  {
    path: `/product/:id`,
    element: (
      <RouteWrapper isBoth>
        <PageProduct />
      </RouteWrapper>
    ),
  },
  {
    path: `/location/:locationID`,
    element: (
      <RouteWrapper isBoth>
        <PageLocation />
      </RouteWrapper>
    ),
  },
  {
    path: `/job/:jobID`,
    element: (
      <RouteWrapper isBoth>
        <PageJob />
      </RouteWrapper>
    ),
  },
  {
    path: `/document/:document`,
    element: (
      <RouteWrapper isBoth>
        <PageDocumentView />
      </RouteWrapper>
    ),
  },
  {
    path: `/changelog`,
    element: (
      <RouteWrapper isBoth>
        <PageChangelog />
      </RouteWrapper>
    ),
  },
  {
    path: '*',
    element: (
      <RouteWrapper>
        <Page404 />
      </RouteWrapper>
    ),
  },
]);

export default router;

import React, { Suspense, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';

import GeneralNotifier from './Notification/GeneralNotifier';
import ProblemNotifier from './Notification/ProblemNotifier';

let id = 0;

const addToast: Function = () => {};
const removeToast: Function = () => {};
const updateToast: Function = () => {};
// Notification Toast Hook

/**
 * Notification Context
 *
 * @category Components
 * @subcategory Notification
 *
 * @component
 */

const ToastContext = React.createContext({
  addToast,
  removeToast,
  updateToast,
});

const ToastContainer = ({ toasts }) => {
  return (
    <>
      {createPortal(
        <div className='NotifierWrapper'>
          <Suspense fallback={<div className=''></div>}>
            {toasts.map((item: any) => (
              <Notify notify={item.content} key={item.id} id={item.id} />
            ))}
          </Suspense>
        </div>,
        document.body
      )}
    </>
  );
};

/**
 * Notification Provider
 *
 * @category Components
 * @subcategory Notification
 *
 * @component
 */
export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback((content) => {
    const a = id++;
    setToasts((toasts) => {
      return [...toasts, { id: a, content }];
    });
    return a;
  }, []);

  const updateToast = useCallback((content, id) => {
    setToasts((toasts) => {
      const tooast = toasts.findIndex((tooast) => tooast.id === id);
      toasts.splice(tooast, 1, { id: id, content });
      return [...toasts];
    });
  }, []);

  const removeToast = useCallback((id) => {
    setToasts((toasts) => toasts.filter((t) => t.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast, updateToast }}>
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
};

/**
 * Notification Hooks
 *
 * @category Components
 * @subcategory Notification
 *
 * @component
 */
export function useToast() {
  const { addToast, removeToast, updateToast } = React.useContext(ToastContext);
  return { addToast, removeToast, updateToast };
}

const Notify = ({ notify, id }) => {
  switch (notify.toast) {


    case 'GENERAL':
      return (
        <GeneralNotifier
          message={notify?.data?.message}
          id={id}
          delay={notify.delay}
        />
      );

    case 'PROBLEM':
      return (
        <ProblemNotifier
          message={notify?.data?.message}
          type={notify?.data?.type}
          id={id}
          delay={notify.delay}
        />
      );
  }
};

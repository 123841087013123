import axios from 'axios';
import { server } from '../Global';
/**
 * @category Backend Connection
 *  @module Util
 * */
/** Load the Count Information */
const loadCounts = async () => {
  const response = await axios.get(server + `/api/v1/util/count`, {
    withCredentials: true,
  });

  return response?.data?.data;
};

export { loadCounts };

import { init } from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import HttpsRedirect from 'react-https-redirect';
import { BallTriangle } from 'react-loader-spinner';
import App from './App';
// import './assets/FALibrary/FALibrary';
import './assets/scss/Index.scss';
import { ApplicationProvider } from './Context/ApplicationContext';
import { ToastProvider } from './Context/NotificationContext';
import { environment, paypal, server } from './Global';
/** Starting Point of React Application */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { ErrorBoundary } from 'react-error-boundary';
// just a commit for redeploy

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ru from 'javascript-time-ago/locale/ru';
TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);
// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

if (environment !== 'development') {
  init({
    dsn: 'https://4b94f37386364b4898b89cf2240a6ac7@o4504880752230400.ingest.sentry.io/4504899950084096',
    integrations: [new BrowserTracing()],
    environment: environment,
    tracesSampleRate: 0.2,
  });
}
const root = createRoot(document.getElementById('root'));
root.render(
  <HttpsRedirect>
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <PayPalScriptProvider
        options={{
          currency: 'EUR',
          clientId: paypal,
        }}
      >
        <ToastProvider>
          <QueryClientProvider client={queryClient}>
            <ApplicationProvider>
              <Suspense
                fallback={
                  <div className='centre vh-100'>
                    <BallTriangle color='#322D31' height={150} width={150} />
                  </div>
                }
              >
                <GoogleReCaptchaProvider reCaptchaKey='6LciVlghAAAAAPkYL4OpaQBg-AP9XTWBj--FwNPT'>
                  <GoogleReCaptcha
                    onVerify={async (e) => {
                      const requestOptions: RequestInit = {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ token: e }),
                      };
                      await fetch(
                        server + '/api/v1/logs/recaptha',
                        requestOptions
                      );
                    }}
                  />
                  <App />
                </GoogleReCaptchaProvider>
              </Suspense>
            </ApplicationProvider>
          </QueryClientProvider>
        </ToastProvider>
      </PayPalScriptProvider>
    </ErrorBoundary>
  </HttpsRedirect>
);

import { useCallback } from 'react';
import { default as io } from 'socket.io-client';
import { server } from '../../Global';

const socket = io(server + '/profile', {
  withCredentials: true,
});

socket.on('connect', () => {
  socket.emit('user.loggedin');
});

const useOnlineProfile = () => {
  const isConnected = useCallback(() => {
    return socket.connected;
  }, []);
  const connect = useCallback(() => {
    if (!socket.connected) {
      socket.connect();
    }
  }, []);
  const disconnect = useCallback(() => {
    if (socket.connected) {
      socket.disconnect();
    }
  }, []);

  return {
    profile_socket: socket,
    connect,
    disconnect,
    // loginUser,
    // logoutUser,
    // userDisconnected,
    // userConnected,
    isConnected,
  };
};

export default useOnlineProfile;

export const userConnected = 'user.connected';
export const userDisconnected = 'user.disconnected';
export const logoutUser = 'user.logout';
export const logoutUserForce = 'user.logout.force';
export const loginUser = 'user.loggedin';

import React from 'react';
import { logoFull } from '../../assets/ImageLibrary/ImageLibrary';
// import { hitlist } from '../../TESTDATA/adminActivities';
interface Props {}
/**
 * The Administration Page for manging Administration functions

 * @category Pages

 *
 * @component
 */

const Page404: React.FC<Props> = () => {
  return (
    <div
      className='container-fluid centre'
      style={{ minHeight: 'calc(100vh - 84.8px - 84.8px)' }}
    >
      <div className=''>
        <img src={logoFull} width='700px' />
        <h1
          className='text-center mt-5'
          style={{ color: '#322D31', fontSize: '65px' }}
        >
          Oops!!
        </h1>
        <h4 className='text-center mt-5'>
          Unfortunately, this page does not exists
        </h4>
      </div>
    </div>
  );
};

export default React.memo(Page404);

import { create } from 'zustand';
import { loadCounts as _loadCounts } from '../BackendConnection/Util';

type Store = {
  count: {
    dashboard: number;
    messages: number;
    projects: number;
    support: number;
    wish: number;
    worklists: number;
    notification: number;
    products: number;
    supervisor: number;
    moodboard: number;
    new_profile: number;
    afflitor: number;
  };

  loadCounts: () => void;
};

const useCount = create<Store>()((set) => ({
  count: {
    dashboard: 0,
    messages: 0,
    projects: 0,
    support: 0,
    wish: 0,
    products: 0,
    worklists: 0,
    notification: 0,
    supervisor: 0,
    moodboard: 0,
    new_profile: 0,
    afflitor: 0,
  },

  loadCounts: async () => {
    const counta = await _loadCounts();
    set(() => ({ count: counta }));
  },
}));

export default useCount;

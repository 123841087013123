import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
// eslint-disable-next-line react/no-deprecated
import { render, unmountComponentAtNode } from 'react-dom';

interface CustomPopupProps {
  value: React.ReactElement;
  size?: ModalProps['size'];
  fullscreen?: ModalProps['fullscreen'];
  close?: Function;
}
interface CustomFCProps {
  value: React.FunctionComponent;
  size?: ModalProps['size'];
  args: any;
}
/** @module Hooks */
/**
 * usePopup hook to popup custom Modals
 *
 * @todo to be corrected to use react 18 DOM
 * @todo used all modal with this hooks
 *

 *
 * @function
 */
const usePopup = () => {
  const customPopup = useCallback(
    (
      value: React.ReactElement,
      size?: ModalProps['size'],
      fullscreen?: ModalProps['fullscreen']
    ) => {
      createCustomPopup({ value, size, fullscreen });
    },
    []
  );
  const customPopupFC = useCallback(
    (value: React.FunctionComponent, size?: ModalProps['size'], ...args) => {
      createFCPopup({ value, size, args });
    },
    []
  );

  return {
    customPopup,
    customPopupFC,
  };
};

export default usePopup;

const CustomPopup = ({ value: Value, size, fullscreen }: CustomPopupProps) => {
  return (
    <Modal
      show
      onHide={() => {
        removeCustomPopup();
      }}
      size={size}
      fullscreen={fullscreen}
      centered
      style={{ paddingRight: 0 }}
      dialogClassName='custom-modal general-popup'
    >
      <Modal.Body>
        <button
          onClick={() => {
            removeCustomPopup();
          }}
          className='absolute right-2 top-2'
        >
          <FontAwesomeIcon
            icon={icon({ name: 'xmark-large', style: 'light' })}
            size='lg'
          />
        </button>
        {Value}
      </Modal.Body>
    </Modal>
  );
};

function createCustomPopup(properties: CustomPopupProps) {
  let divTarget = document.getElementById('general-popup');
  if (divTarget) {
    // Rerender - the mounted ReactConfirmAlert
    render(<CustomPopup {...properties} />, divTarget);
  } else {
    // Mount the ReactConfirmAlert component
    divTarget = document.createElement('div');
    divTarget.id = 'general-popup';
    document.body.appendChild(divTarget);
    render(<CustomPopup {...properties} />, divTarget);
  }
}

function removeCustomPopup() {
  const target = document.getElementById('general-popup');
  if (target) {
    unmountComponentAtNode(target);
    target.parentNode && target.parentNode.removeChild(target);
  }
}

const FCPopup = ({ value: Value, size }: CustomFCProps) => {
  return (
    <Modal
      show
      onHide={() => {
        removeFCPopup();
      }}
      size={size}
      centered
      dialogClassName='custom-modal general-popup'
    >
      <Modal.Body>
        <Value />
      </Modal.Body>
    </Modal>
  );
};

function createFCPopup(properties: CustomFCProps) {
  let divTarget = document.getElementById('fc-popup');
  if (divTarget) {
    // Rerender - the mounted ReactConfirmAlert
    render(<FCPopup {...properties} />, divTarget);
  } else {
    // Mount the ReactConfirmAlert component
    divTarget = document.createElement('div');
    divTarget.id = 'fc-popup';
    document.body.appendChild(divTarget);
    render(<FCPopup {...properties} />, divTarget);
  }
}

export function removeFCPopup() {
  const target = document.getElementById('fc-popup');
  if (target) {
    unmountComponentAtNode(target);
    target.parentNode && target.parentNode.removeChild(target);
  }
}

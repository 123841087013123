import axios from 'axios';
import { DateTime } from 'luxon';
import { useCallback, useContext, useEffect } from 'react';
import { server } from '../../Global';
import useAction from '../../hooks/Sockets/useAction';
import useActivity from '../../hooks/Sockets/useActivity';
import useChat from '../../hooks/Sockets/useChat';
import useOnlineProfile, {
    logoutUser,
    userConnected,
    userDisconnected,
} from '../../hooks/Sockets/useOnlineProfile';
import { ApplicationContext } from '../ApplicationContext';
import { useToast } from '../NotificationContext';
import useTranslate from './useTranslate';

const useUser = () => {
  const { user } = useContext(ApplicationContext);
  const {
    profile_socket,
    connect: connectOnlineProfile,
    disconnect: disconnectOnlineProfile,
  } = useOnlineProfile();
  const { connect: connectChat, disconnect: disconnectChat } = useChat();
  const { connect: connectAction, disconnect: disconnectAction } = useAction();
  const { connect: connectActivity, disconnect: disconnectActivity } =
    useActivity();
  const { t } = useTranslate();
  const { addToast } = useToast();

  const { state, setState } = user;

  useEffect(() => {
    const _userConnected = (message) => {
      setState &&
        setState((state) => {
          if (!state) return state;
          if (message._id === state._id) {
            return { ...state, online: true };
          }
          return state;
        });
    };
    const _userDisconnected = (message) => {
      setState &&
        setState((state) => {
          if (!state) return state;
          if (message._id === state._id) {
            return { ...state, online: false };
          }
          return state;
        });
    };
    profile_socket.on(userDisconnected, _userDisconnected);
    profile_socket.on(userConnected, _userConnected);
    return () => {
      profile_socket.off(userConnected, _userConnected);
      profile_socket.off(userDisconnected, _userDisconnected);
    };
  }, [profile_socket, setState]);

  const logout = useCallback(async () => {
    await axios.get(server + '/api/v1/profiles/logout', {
      withCredentials: true,
    });

    profile_socket.emit(logoutUser);

    disconnectOnlineProfile();
    disconnectChat();
    disconnectActivity();
    disconnectAction();
    setState(null);
  }, [
    disconnectAction,
    disconnectActivity,
    disconnectChat,
    disconnectOnlineProfile,
    profile_socket,
    setState,
  ]);

  const refresh = useCallback(async () => {
    try {
      const response = await axios.get(server + '/api/v1/profiles/me', {
        withCredentials: true,
      });

      if (response?.data?.success) {
        connectOnlineProfile();
        connectChat();
        connectAction();
        connectActivity();
        setState(response?.data?.data);
      } else {
        setState(null);
      }
    } catch {
      setState(null);
    }
  }, [
    connectAction,
    connectActivity,
    connectChat,
    connectOnlineProfile,
    setState,
  ]);

  const authToken = useCallback(async () => {
    try {
      const response = await axios.get(
        server + '/api/v1/profiles/refresh-token',
        {
          withCredentials: true,
        }
      );

      if (response?.data?.success) {
        refresh();
      } else {
        setState(null);
      }
    } catch {
      setState(null);
    }
  }, [refresh, setState]);

  const signup = useCallback(
    async ({ form, affliate }) => {
      try {
        await axios.post(
          server + '/api/v1/profiles',
          {
            ...form,
            affliatedTo: affliate?._id,
          },
          { withCredentials: true }
        );
        authToken();
      } catch ({ response }) {
        addToast({

          data: { message: t(response?.data?.data) },
          toast: 'GENERAL',
        });
      }
    },
    [addToast, authToken, t]
  );

  const login = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        await axios.post(
          server + '/api/v1/profiles/login',
          {
            email: email,
            password: password,
          },
          { withCredentials: true }
        );
        authToken();
      } catch ({ response }) {
        addToast({

          data: { message: t(response?.data?.error) },
          toast: 'GENERAL',
        });
      }
    },
    [addToast, authToken, t]
  );

  const update = useCallback(
    async (data: Object) => {
      try {
        const response = await axios.put(server + '/api/v1/profiles/me', data, {
          withCredentials: true,
        });
        setState(response?.data?.data);
      } catch ({ response }) {
        addToast({

          data: { message: t(response?.data?.error) },
          toast: 'GENERAL',
        });
      }
    },
    [addToast, setState, t]
  );
  const isMinor = useCallback(() => {
    if (state?.birthday) {
      return DateTime.fromISO(state?.birthday).diffNow().as('years') > -18;
    }
    return true;
  }, [state?.birthday]);

  const isMetrixPrefered = useCallback(() => {
    if (state?.settings.measurementSystem === 'METRIC') {
      return true;
    }
    return false;
  }, [state?.settings.measurementSystem]);

  const genderChoice = useCallback(
    (gender: string) => {
      if (gender === 'All') {
        return true;
      }
      if (gender === 'Men') {
        return state?.gender === 'Male';
      }
      if (gender === 'Women') {
        return state?.gender === 'Female';
      }
      if (gender === 'Individual') {
        return state?.gender === 'Divers';
      }

      return false;
    },
    [state?.gender]
  );

  return {
    user: state,
    login,
    refresh,
    authToken,
    update,
    logout,
    signup,
    isMinor,
    isMetrixPrefered,
    genderChoice,
  };
};

export default useUser;

import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useEffect, useState } from 'react';
import ReactTimeAgo from 'react-time-ago';
import { CSSTransition } from 'react-transition-group';
import useTranslate from '../ContextHooks/useTranslate';
import { useToast } from '../NotificationContext';

interface Props {
  message: string;
  id: number;
  delay?: number;
  static?: boolean;
}

/**
 * General Notification to be used with any notification
 *
 * @category Components
 * @subcategory Notification
 *
 * @component
 */

const GeneralNotifier: React.FC<Props> = (props) => {
  const { removeToast } = useToast();
  const { t } = useTranslate();
  const [date] = useState(new Date());

  useEffect(() => {
  const timer = setTimeout(() => {
      // removeToast(props.id);
    }, props.delay); // delay

    return () => {
      clearTimeout(timer);
    };
  }, [props.delay, props.id, removeToast]);

  return (
    <CSSTransition
      in={true}
      timeout={300}
      classNames='notifier'
      appear
      mountOnEnter
      unmountOnExit
    >
      <div className='border-1 mt-3 w-72 rounded border-Border_Color bg-Primary_Color text-Text_Color'>
        <div className='border-1 flex justify-between border-Border_Color px-2 py-2'>
          <div className='flex'>
            <FontAwesomeIcon icon={icon({ name: 'icons', style: 'light' })} />
            <h6 className='ml-1'>General</h6>
          </div>
          <div className='flex'>
            <h6 className='mr-1'>
              <Countdown date={date} />
            </h6>
            <FontAwesomeIcon
              className='cursor-pointer'
              onClick={() => {
                removeToast(props.id);
              }}
              icon={icon({ name: 'times', style: 'light' })}
            />
          </div>
        </div>
        <div className='border-1 border-Border_Color p-2'>
          <p>{t(props.message)}</p>
        </div>
      </div>
    </CSSTransition>
  );
};
GeneralNotifier.defaultProps = {
  delay: 4000,
  static: false,
};
export default React.memo(GeneralNotifier);

const Countdown = ({ date }: { date: number | Date }) => {
  const [, seupdate] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      seupdate((update) => {
        return ++update;
      });
    }, 1000); // delay

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <ReactTimeAgo date={date} locale='en-US' timeStyle={'round'} />;
};

/** Importing Static Images */

import Blank_Picture from './Images/Blank_Picture.png';
import HowTo from './Images/HowTo.png';
import LandingPageTop from './Images/LandingPageTop.jpg';
import landingPageBottom from './Images/landingPageBottom.jpg';
import landingPageVideo from './Images/landingPageVideo.mp4';
import landingcarousel1 from './Images/landingcarousel-1.jpg';
import landingcarousel2 from './Images/landingcarousel-2.jpg';
import landingcarousel3 from './Images/landingcarousel-3.jpg';
import landingcarousel4 from './Images/landingcarousel-4.jpg';
import staging from './STAGE_1.svg';
import CocaineModels from './ads/cocaineModels.jpg';
import FivModels from './ads/fivModels.jpg';
import gallery from './gallery.jpg';
import llogoSmall from './logo/logo.svg';
import llogoFull from './logo/logoFull.png';
import llogoRedSmall from './logo/logoRed.svg';
import llogoRedWhite from './logo/logoRedWhite.svg';
import llogoWhiteFull from './logo/logoWhiteFull.svg';

let logoSmall = llogoSmall;
let logoFull = llogoFull;
let logoRedSmall = llogoRedSmall;
let logoRedWhite = llogoRedWhite;
let logoWhiteFull = llogoWhiteFull;

if (
  window.location.hostname === 'www.stage-mrz-marooze.com' ||
  window.location.hostname === 'localhost'
) {
  logoSmall = staging;
  logoFull = staging;
  logoRedSmall = staging;
  logoRedWhite = staging;
  logoWhiteFull = staging;
}
export {
  Blank_Picture,
  CocaineModels,
  FivModels,
  HowTo,
  LandingPageTop,
  gallery,
  landingPageBottom,
  landingPageVideo,
  landingcarousel1,
  landingcarousel2,
  landingcarousel3,
  landingcarousel4,
  logoFull,
  logoRedSmall,
  logoRedWhite,
  logoSmall,
  logoWhiteFull
};

import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import useTranslate from '../ContextHooks/useTranslate';
import { useToast } from '../NotificationContext';

interface Props {
  message: string;
  type: 'WARNING' | 'ERROR';
  id: number;
  delay?: number;
}

/**
 * Problem Notification when some one goes wrong
 *
 * @category Components
 * @subcategory Notification
 *
 * @component
 */
const ProblemNotifier: React.FC<Props> = (props) => {
  const { removeToast } = useToast();
  const { t } = useTranslate();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(props.id);
    }, props.delay); // delay

    return () => {
      clearTimeout(timer);
    };
  }, [props.delay, props.id, removeToast]);

  return (
    <CSSTransition
      in={true}
      timeout={300}
      classNames='notifier'
      appear
      mountOnEnter
      unmountOnExit
    >
      <div className='notifier problem container-fluid'>
        <div className='row'>
          <div
            className='centre'
            style={{
              flex: '0 0 auto',
              width: '20%',
            }}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'exclamation-triangle', style: 'light' })}
              color={props.type === 'ERROR' ? '#fff' : '#fff'}
            />
          </div>
          <div
            className='text'
            style={{
              flex: '0 0 auto',
              width: '80%',
            }}
          >
            <p>{t(props.message)}</p>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
ProblemNotifier.defaultProps = {
  delay: 4000,
};
export default React.memo(ProblemNotifier);

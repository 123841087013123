import axios from 'axios';
import { useCallback, useContext } from 'react';
import { server } from '../../Global';
import { ApplicationContext } from '../ApplicationContext';

const useConstants = () => {
  const { constants } = useContext(ApplicationContext);
  // const [loaded, setLoaded] = useState(false);

  const { state, setState } = constants;

  const getConstant = useCallback(
    (_id) => {
      return state.find((f) => f._id === _id);
    },
    [state]
  );

  const refreshConsants = useCallback(async () => {
    const response = await axios.get(server + '/api/v1/documents/constant', {
      withCredentials: true,
    });

    setState(response?.data?.data);
  }, [setState]);

  // useEffect(() => {
  //   if (!loaded) {
  //     refreshConsants();
  //     setLoaded(true);
  //   }
  // }, [loaded]);

  return { constants: state, getConstant, refresh: refreshConsants };
};

export default useConstants;

import axios from 'axios';
import find from 'lodash/find';
import { useCallback, useContext } from 'react';
import { server } from '../../Global';
import { ApplicationContext } from '../ApplicationContext';
const usePermission = () => {
  const { permission, user: userContext } = useContext(ApplicationContext);
  // const [loaded, setLoaded] = useState(false);

  const { state, setState } = permission;
  const { state: user } = userContext;

  const refresh = useCallback(async () => {
    const response = await axios.get(server + '/api/v1/permissions/me', {
      withCredentials: true,
    });

    setState(response?.data?.data);
  }, [setState]);

  const getPermission = useCallback(
    (_id: string) => {
      if (state !== null) {
        let lang = find(state, { _id });

        if (lang) return lang.permission;
        return false;
      }
      return 'NOT_INIT';
    },
    [state]
  );
  const isAdministrator = useCallback(() => {
    switch (user?.user_type) {
      case 'Administrator':
        return true;
      case 'Supervisor':
      case 'Freemium':
      case 'Premium':
      default:
        return false;
    }
  }, [user?.user_type]);
  const isSupervisor = useCallback(() => {
    switch (user?.user_type) {
      case 'Supervisor':
      case 'Administrator':
        return true;
      case 'Freemium':
      case 'Premium':
      default:
        return false;
    }
  }, [user?.user_type]);
  const isPremium = useCallback(() => {
    switch (user?.user_type) {
      case 'Premium':
      case 'Supervisor':
      case 'Administrator':
        return true;
      case 'Freemium':
      default:
        return false;
    }
  }, [user?.user_type]);
  const isFreemium = useCallback(() => {
    switch (user?.user_type) {
      case 'Premium':
      case 'Supervisor':
      case 'Administrator':
        return false;
      case 'Freemium':
      default:
        return true;
    }
  }, [user?.user_type]);

  const isPremiumUser = useCallback((user) => {
    switch (user?.user_type) {
      case 'Premium':
      case 'Supervisor':
      case 'Administrator':
        return true;
      case 'Freemium':
      default:
        return false;
    }
  }, []);

  return {
    isPremiumUser,
    permission: state,
    refresh: refresh,
    isSupervisor,
    isPremium,
    isAdministrator,
    isFreemium,
    getPermission,
  };
};

export default usePermission;

// eslint-disable-next-line no-unused-vars
const LanguageOptions = () => {
  return [
    {
      // flag: usaFlag,
      display: 'English',
      iso2: 'EN',
      value: 'en-US',
    },
    {
      // flag: germanyFlag,
      display: 'German',
      iso2: 'DE',
      value: 'de-DE',
    },
    {
      // flag: franceFlag,
      display: 'French',
      iso2: 'FR',
      value: 'fr-FR',
    },
    {
      // flag: italyFlag,
      display: 'Italian',
      iso2: 'IT',
      value: 'it-IT',
    },
    {
      // flag: russiaFlag,
      display: 'Russian',
      iso2: 'RU',
      value: 'ru-RU',
    },
    {
      // flag: czechFlag,
      display: 'Czech',
      iso2: 'CS',
      value: 'cs-CZ',
    },
    {
      // flag: swedenFlag,
      display: 'Swedish',
      iso2: 'SV',
      value: 'sv-SE',
    },
    {
      // flag: spainFlag,
      display: 'Spanish',
      iso2: 'ES',
      value: 'es-ES',
    },
    {
      // flag: polandFlag,
      display: 'Polish',
      iso2: 'PL',
      value: 'pl-PL',
    },

    {
      // flag: hungaryFlag,
      display: 'Hungarian',
      iso2: 'HU',
      value: 'hu-HU',
    },
    {
      // flag: denmarkFlag,
      display: 'Danish',
      iso2: 'DA',
      value: 'da-DK',
    },
    {
      // flag: greeceFlag,
      display: 'Greek',
      iso2: 'EL',
      value: 'el-GR',
    },
    {
      // flag: turkeyFlag,
      display: 'Turkish',
      iso2: 'TR',
      value: 'tr-TR',
    },
    {
      // flag: chinaFlag,
      display: 'Chinese',
      iso2: 'ZH',
      value: 'zh-CHT',
    },
    {
      // flag: portugalFlag,
      display: 'Portuguese',
      iso2: 'PT',
      value: 'pt-PT',
    },
    {
      // flag: japanFlag,
      display: 'Japanese',
      iso2: 'JA',
      value: 'ja-JP',
    },
    {
      // flag: netherlandsFlag,
      display: 'Dutch',
      iso2: 'NL',
      value: 'nl-NL',
    },
  ];
};

export default LanguageOptions;

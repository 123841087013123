import axios from 'axios';
// import { AsyncParser } from '@json2csv/whatwg';
import uniqBy from 'lodash/uniqBy';
import { server } from '../Global';
/**
 * @category Backend Connection
 *  @module Texts
 * */
/** Load The Selected Language */
const loadLanguage = async (lang) => {
  const response = await axios.get(server + `/api/v1/texts/${lang}`, {
    withCredentials: true,
  });

  const texts = uniqBy(response?.data?.data?.texts, '_id');

  // const data = texts.map((u: any) => {
  //   return {
  //     _id: u._id,
  //     description: u.description,
  //     'EN': u.text,
  //     'DE': '',
  //     'FR': '',
  //     'BE': '',
  //   };
  // });

  // const json2csvParser: any = new AsyncParser();
  // const csv = await json2csvParser.parse(data).promise();

  // // Creating a Blob for having a csv file format
  // // and passing the data with type
  // const blob = new Blob([csv], { type: 'text/csv' });

  // // Creating an object for downloading url
  // const url = window.URL.createObjectURL(blob);

  // // Creating an anchor(a) tag of HTML
  // const a = document.createElement('a');

  // // Passing the blob downloading url
  // a.setAttribute('href', url);

  // // Setting the anchor tag attribute for downloading
  // // and passing the download file name
  // a.setAttribute('download', 'Translation.csv');

  // // Performing a download with click
  // a.click();

  return {
    ...response?.data?.data,
    texts: texts,
  };
};
/** Update the Selected Language */
const updateLanguage = async (lang, data1) => {
  const response = await axios.put(server + `/api/v1/texts/${lang}`, data1, {
    withCredentials: true,
  });

  return response?.data?.data;
};

export { loadLanguage, updateLanguage };

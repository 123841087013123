import { useCallback } from 'react';
import { default as io } from 'socket.io-client';
import { server } from '../../Global';

const socket = io(server + '/activity', {
  withCredentials: true,
});

socket.on('connect', () => {
  socket.emit('storeID');
});

const useActivity = () => {
  const isConnected = useCallback(() => {
    return socket.connected;
  }, []);
  const connect = useCallback(() => {
    socket.connect();
  }, []);
  const disconnect = useCallback(() => {
    socket.disconnect();
  }, []);

  return {
    activity_socket: socket,
    connect,
    disconnect,
    isConnected,
  };
};

export default useActivity;

export const recieveActivity = 'activity.forword';
export const newActivity = 'activity.new';

import { useCallback } from 'react';
import { default as io } from 'socket.io-client';
import { server } from '../../Global';

const socket = io(server + '/action', {
  withCredentials: true,
  autoConnect: false,
});

socket.on('connect', () => {
  socket.emit('storeID');
  // setIsConnected(true);
});

const useAction = () => {
  const isConnected = useCallback(() => {
    return socket.connected;
  }, []);

  const connect = useCallback(() => {
    socket.connect();
  }, []);
  const disconnect = useCallback(() => {
    socket.disconnect();
  }, []);

  return {
    action_socket: socket,
    connect,
    disconnect,
    isConnected,
  };
};

export default useAction;

export const sendMessage = 'message.send';
export const recieveMessage = 'message.recieve';
export const sendRead = 'message.read';

export const deleteMessage = 'message.delete';
export const deleteMessageRecieve = 'message.delete.recieve';

export const deleteChat = 'chat.delete';

export const recieveReadMessage = 'message.recieve.read';
export const sendStartedTyping = 'message.write';
export const recieveTyping = 'message.write.send';
export const exitGroupChat = 'message.group.exit';
export const editGroupChat = 'message.group.edit';
